import _ from 'lodash';
import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';

export const UPLOAD_URL = API_ROOT + '/api/documents/';
const APPLICATIONS_URL = API_ROOT + '/api/applications/';
const AUTHORISERS_URL = API_ROOT + '/api/approvals/';

const ACCOUNTS_URL = API_ROOT + '/api/accounts/';
const SIGNATORIES_URL = API_ROOT + '/api/signatories/';
const PBVERIFY_URL = API_ROOT + '/api/individual/';

const PEOPLE_URL = API_ROOT + '/api/people?idnum=';
const CORPAUTHPERSONS_URL = API_ROOT + '/api/gt-corporates/';
const KYC_MAINTENANCE_URL = API_ROOT + '/api/kyc-maintenance';

export function verifyDoc(supportingDocuments, documentType, applicationId, documentId = null, onSuccess = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            let newDoc = true;

            _.map(supportingDocuments, async doc => {
                if (doc.documentType === documentType) {
                    newDoc = false;
                    doc.verified = true;
                    if (documentId) {
                        doc.documentId = _.isArray(documentId) ? documentId[0] : documentId;
                    }
                    const responseData = await axios.put(APPLICATIONS_URL + applicationId + '/legalEntity/docs', doc).then(response => response.data);
                    if (responseData.success) {
                        onSuccess && onSuccess();
                        dispatch({type: types.DOC_VERIFY_SUCCESS, application: responseData.data});
                    } else {
                        console.log('kyc.verifyDoc.errorMessage ===>', responseData.message);
                        dispatch({
                            type: types.SHOW_RESPONSE_ERROR,
                            errorMessage: 'Couldn\'t verify existing document.'
                        });
                    }
                }
                return doc;
            });

            if (newDoc) {
                const responseData = await axios.post(APPLICATIONS_URL + applicationId + '/legalEntity/docs', {
                    documentType,
                    'name': documentType,
                    'verified': true,
                    documentId: _.isArray(documentId) ? documentId[0] : documentId,
                }).then(response => response.data);

                if (responseData.success) {
                    onSuccess && onSuccess();
                    dispatch({type: types.DOC_UPLOAD_SUCCESS, application: responseData.data});
                } else {
                    console.log('kyc.uploadDoc.errorMessage ===>', responseData.message);
                    dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t verify new document.'});
                }
            }

        } catch (error) {
            console.log('kyc.verifyDoc.error ===>', error);
            authCheck(dispatch, error);
        }
    };
}

export function updateDoc(doc) {
    return async (dispatch) => {
        dispatch({type: types.DOC_UPDATE_SUCCESS, doc: doc});
    };
}

export function updateNewEntityDoc(doc) {
    return async (dispatch) => {
        dispatch({type: types.NEW_ENTITY_DOC_UPDATE_SUCCESS, doc: doc});
    };
}

export function uploadDoc(base64, onSuccess, onError, onUploadInProgress = null, onCompleteUpload = null) {
    return async (dispatch) => {
        const config = {
            onUploadProgress: progressEvent => onUploadInProgress && onUploadInProgress(progressEvent)
        };
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const strippedBase64 = base64.split(',')[1];

            const responseData = await axios.post(UPLOAD_URL, {'base64Content': strippedBase64}, config).then(response => response.data);

            onCompleteUpload && onCompleteUpload(responseData);
            if (responseData.success) {
                const documentId = responseData.message;
                onSuccess && onSuccess(documentId, responseData.data);
            } else {
                onSuccess && onSuccess(null, null);
                console.log('kyc.uploadDoc.errorMessage ===>', responseData.message);
                onError && onError('Couldn\'t upload document.');
            }
        } catch (error) {
            console.log('kyc.uploadDoc.error ====>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onError && onError('Couldn\'t upload document.', status);
        }
    };
}

export function addRelatedParty(applicationId, relatedParty) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            const supportingDocuments = _.map(relatedParty.supportingDocuments, async (doc) => {
                const responseData = await axios.post(UPLOAD_URL, {'base64Content': doc.documentId}).then(response => response.data);
                return _.extend({}, doc, {documentId: responseData.message});
            });

            const responseData = await axios.post(APPLICATIONS_URL + applicationId + '/legalEntity/relatedParty', _.extend({}, relatedParty, {supportingDocuments})).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.RELATED_PARTY_SUCCESS, application: responseData.data});
            } else {
                console.log('kyc.addRelatedParty.errorMessage ===>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add related party.'});
            }
        } catch (error) {
            console.log('kyc.addRelatedParty.error ===>', error);
            authCheck(dispatch, error);
        }
    };
}

export function addRelatedDoc(documentType, base64, name) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const strippedBase64 = base64.split(',')[1];
            const responseData = await axios.post(UPLOAD_URL, {'base64Content': strippedBase64}).then(response => response.data);

            if (responseData.success) {
                const supportingDoc = {documentType, verified: true, documentId: responseData.message, name};
                dispatch({type: types.RELATED_DOC_UPLOAD_SUCCESS, supportingDoc});
            } else {
                console.log('kyc.addRelatedDoc.errorMessage ===>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add related party document.'});
            }
        } catch (error) {
            console.log('kyc.addRelatedDoc.error ===>', error);
            authCheck(dispatch, error);
        }
    };
}

export function fetchAccounts(queryString, objName, onCompleted = null, enableLoading = true) {
    return async (dispatch) => {
        try {
            enableLoading && loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${ACCOUNTS_URL}?${queryString}`).then(response => response.data);

            onCompleted && onCompleted();
            if (responseData.success) {
                const hasAccounts = _.size(responseData.data) > 0;
                if (hasAccounts) {
                    console.log('kyc.fetchAccounts.hasAccounts ===> ', hasAccounts);
                    const accounts = _.filter(responseData.data, acc => (acc.active));
                    dispatch({type: types.FETCH_ACCOUNTS_SUCCESS, objName, accounts});
                } else {
                    const accounts = [];
                    dispatch({type: types.FETCH_ACCOUNTS_EMPTY, accountsEmpty: true, objName, accounts});
                }
            } else {
                console.log('kyc.fetchAccounts.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve accounts.'});
            }
            enableLoading && loadingPageDataFinish(dispatch);
        } catch (error) {
            onCompleted && onCompleted();

            console.log('kyc.fetchAccounts.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            enableLoading && loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchIndiviualInfo(idNumber, onFinish, individualType, onError) {
    return async (dispatch) => {
        try {
            dispatch({type: types.CLEAR_INDIVIDUAL_INFO});
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(PBVERIFY_URL + idNumber).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FECTH_INDIVIDUAL_SUCCESS, individual: responseData.data, individualType});
                onFinish && onFinish(true, responseData.data);
            } else {
                console.log('kyc.fetchIndiviualInfo.errorMessage ===>', responseData.message);
                onFinish && onFinish(false);
            }
        } catch (error) {
            const status = error.response && error.response.status;
            if (status === 404) {
                console.log(status);
                onError && onError();
                loadingPageDataFinish(dispatch);
                return;
            }
            onFinish && onFinish(false);
            console.log('kyc.fetchIndiviualInfo.errorMessage ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function fetchSignatoriesForAccount(queryString, accountNumber, onComplete = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            const responseData = await axios.get(`${SIGNATORIES_URL}${accountNumber}?${queryString}`).then(response => response.data);

            onComplete && onComplete(responseData.data);
            if (responseData.success) {
                const response = [];
                responseData.data.forEach(sign => {
                    sign.lastName = sign.surname;
                    response.push(sign);
                });
                dispatch({type: types.FETCH_SIGNATORY_FOR_ACC_SUCCESS, copies: response});
            } else {
                console.log('kyc.fetchSignatoriesForAccount.errorMessage ===>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve signatories.'});
            }
        } catch (error) {
            onComplete && onComplete();
            console.log('kyc.fetchSignatoriesForAccount.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function fetchSignatoriesForCif(cifNumber, onComplete = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            const responseData = await axios.get(`${SIGNATORIES_URL}all/${cifNumber}`).then(response => response.data);

            onComplete && onComplete(responseData.data);
            if (responseData.success) {
                responseData.data.signatories.forEach(sign => {
                    sign.lastName = sign.surname;
                });
                loadingPageDataFinish(dispatch);
                dispatch({type: types.FETCH_SIGNATORY_FOR_ACC_SUCCESS, copies: responseData.data});
            } else {
                console.log('kyc.fetchSignatoriesForAccount.errorMessage ===>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve signatories.'});
            }
        } catch (error) {
            onComplete && onComplete();
            console.log('kyc.fetchSignatoriesForAccount.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}


export function removeSignatory(applicationId, signatory, onComplete) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${APPLICATIONS_URL}${applicationId}/account/signatories/${signatory && signatory.id}`;
            const responseData = await axios.delete(url).then(response => response.data);

            if (responseData.success) {
                onComplete && onComplete(true);
                dispatch({type: types.REMOVE_SIGNATORY_SUCCESS, application: responseData.data});
            } else {
                onComplete && onComplete();
                console.log('kyc.removeSignatory.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t remove signatory.'});
            }
        } catch (error) {
            console.log('kyc.removeSignatory.errorMessage ===>', error);
            onComplete && onComplete();
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            dispatch({type: types.SYSTEM_ERROR, status});
        }
    };
}

export function addSignatories(applicationId, signatories, onCompleted = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(APPLICATIONS_URL + applicationId + '/account/signatories', signatories).then(response => response.data);
            onCompleted && onCompleted();

            if (responseData.success) {
                dispatch({type: types.ADD_SIGNATORY_SUCCESS, application: responseData.data});
            } else {
                console.log('kyc.addSignatory.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add signatory.'});
            }
        } catch (error) {
            onCompleted && onCompleted();
            console.log('kyc.addSignatory.errorMessage ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            dispatch({type: types.SYSTEM_ERROR, status});
        }
    };
}

export function updateRelatedParty(applicationId, relatedParty) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${APPLICATIONS_URL}${applicationId}/legalEntity/relatedParty/${relatedParty.id}`;
            const rp = _.extend({}, relatedParty, {actionType: 'MODIFIED'});

            const responseData = await axios.put(url, rp).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.RELATED_PARTY_SUCCESS, application: responseData.data});
            } else {
                console.log('kyc.updateRelatedParty.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t update related party.'});
            }
        } catch (error) {
            console.log('kyc.updateRelatedParty.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function removeRelatedParty(applicationId, relatedParty) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${APPLICATIONS_URL}${applicationId}/legalEntity/relatedParty/${relatedParty.id}`;
            const rp = _.extend({}, relatedParty, {actionType: 'REMOVED'});

            const responseData = await axios.put(url, rp).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.RELATED_PARTY_SUCCESS, application: responseData.data});
            } else {
                console.log('kyc.removeRelatedParty.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t remove related party.'});
            }
        } catch (error) {
            
            console.log('kyc.removeRelatedParty.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function confirmKYC(appId, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(AUTHORISERS_URL + appId).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_AUTHORISERS, authorisers: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('kyc.confirmKYC.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm KYC.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('kyc.confirmKYC.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}

export function complete(id, emailAddress, onProceed,selectedApprovers) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(APPLICATIONS_URL + id, {'emailAddress': emailAddress,selectedApprovers}).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.APP_CONFIRM_SUCCESS, application: responseData.data,});
                onProceed && onProceed();
            } else {
                console.log('kyc.complete.errorMessage ===> ', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t complete account opening application.'
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('kyc.complete.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}
export function addKycChanges(applicationId, change) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(APPLICATIONS_URL + applicationId + '/kyc', change).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.ADD_KYC_CHANGE_SUCCESS, application: responseData.data});
            } else {
                console.log('kyc.addKycChanges.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add kyc changes.'});
            }
        } catch (error) {
            console.log('kyc.addKycChanges.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function clearIndiviualInfo(individualType) {
    return dispatch => dispatch({type: types.CLEAR_INDIVIDUAL_INFO, individualType});
}

export function selectSignatory(copies, signatory, isSelected) {
    return dispatch => {
        const updatedcopies = _.map(copies, copy => {

            const isMatch = copy.idOrPassportNumber === signatory.idOrPassportNumber;

            if (isMatch) {
                return _.extend({}, copy, {isSelected});
            }

            return copy;
        });
        dispatch({type: types.UPDATE_COPIES, copies: updatedcopies});
    };
}

export function selectAllSignatories(copies, isSelected) {
    return dispatch => {
        const updatedCopies = _.map(copies, copy => (_.extend({}, copy, {isSelected})));
        dispatch({type: types.UPDATE_COPIES, copies: updatedCopies});
    };
}


export function clearCopies(){
    return dispatch => (dispatch({type: types.CLEAR_COPIES}));
}

export function onSelectAccount(accounts, account, isSelected, objName = null, isMultiSelect = true, deselectAcc = null, onComplete) {
    return dispatch => {
        let updatedAccounts;
        if (isMultiSelect) {
            updatedAccounts = _.map(accounts, acc => {
                if (acc.accountNumber === account.accountNumber) {
                    let shortName = account.shortName;
                    return _.extend({}, acc, {isSelected, shortName});
                }
                return acc;
            });
        } else {
            updatedAccounts = _.map(accounts, acc => {
                const select = acc.accountNumber === (account && account.accountNumber);
                const deselect = acc.accountNumber === (deselectAcc && deselectAcc.accountNumber);
                if (select) {
                    return _.extend({}, acc, {isSelected: true});
                } else if (deselect) {
                    return _.extend({}, acc, {isSelected: false});
                }
                return acc;
            });
        }
        dispatch({type: types.UPDATE_ACCOUNTS, accounts: updatedAccounts, objName});
        _.defer(() => onComplete && onComplete());
    };
}

export function onSelectAllAccounts(accounts, isSelected, objName = null, filteredAccounts = null, onComplete) {
    return dispatch => {
        let updatedAccounts;
        if (_.size(filteredAccounts) > 0) {
            updatedAccounts = _.map(accounts, acc => {
                const found = _.find(filteredAccounts, a => a.accountNumber === acc.accountNumber);
                return _.extend({}, acc, {isSelected: found ? isSelected : !isSelected});
            });
        } else {
            updatedAccounts = _.map(accounts, acc => (_.extend({}, acc, {isSelected})));
        }
        dispatch({type: types.UPDATE_ACCOUNTS, accounts: updatedAccounts, objName});
        _.defer(() => onComplete && onComplete());
    };
}

export function fetchAuthorisedPersons(gtid){
    return async dispatch => {
        const url = CORPAUTHPERSONS_URL + gtid + '/authorized';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        const responseData = await axios.get(url).then(response => response.data);
        console.log('Authorized', responseData);
        if (responseData.success) {
            dispatch({type: types.FETCH_AUTHORISERS, authorisedPersons: responseData.data});
        } else {
            console.log('kyc.fetchAuthPersons.errorMessage ===> ', responseData.message);
            dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t fetch Authorised Persons.'});
        }
    };
}
