import React, { Component } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DataList } from 'primereact/components/datalist/DataList';
import * as actions from '../../actions/delegation';
import FormField from "../formField/FormField";
import validate from "validate.js";
import Modal from "react-bootstrap/lib/Modal";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Toggler from "../Toggler";
import SearchComponent from "../SearchComponent";
import { fetchIndiviualInfo } from "../../actions/kyc";
class DelegateAuthorityPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			delegated: {},
			errors: {},
			isEdit: false,
			showDelegatedPersonPopUp: false,
			editShowDelegatedPersonPopUp: false,
			isRSACitizen: true,
			isSearchComplete: false,
			isLoading: false,
			selectAllProducts: false,
			checkedItems: new Map()
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleRulesEnabled = this.handleRulesEnabled.bind(this);
		this.remove = this.remove.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
		this.resetError = this._resetError.bind(this);
	}


	componentWillMount() {
		this.props.actions.fetchDelegates();
	}
	componentWillReceiveProps() {
		this.setState({ delegated: { firstName: '', lastName: '', impersonatorIdentityNumber: '', products: [], rulesEnabled: false, editShowDelegatedPersonPopUp: false } });
	}
	handleAddDelegate = (e) => {
		e.preventDefault();
		if (this._validateForm()) {
			const onComplete = () => this.onToggleDelegatedPopUp();
			this.props.actions.addDelegate(this.state.delegated, onComplete);
		}
	}
	handleEditDelegate = (e) => {
		e.preventDefault();
		if (this._validateForm()) {
			const onComplete = () => onToggleDelegatedEditPopUp();
			this.props.actions.updateDelegate(this.state.delegated, onComplete);
			this.setState({ editShowDelegatedPersonPopUp: false });
		}
	}
	handleChangeSACitizen = (isRSACitizen) => {
		const { delegated } = this.state
		// if (!isRSACitizen) {
		delegated.firstName = "";
		delegated.impersonatorIdentityNumber = "";
		delegated.lastName = "";
		delegated.products = [];
		delegated.rulesEnabled = false;
		this.setState({ selectAllProducts: false });
		// }
		this.setState({ isRSACitizen, delegated });
	};
	remove(d) {
		this.props.actions.removeDelegate(d);
	}
	updateDelegate(d) {
		this.props.actions.updateDelegate(d);
	}

	editDelegate = (delegate) => {
		this.setState({ delegated: { ...delegate }, isEdit: true, editShowDelegatedPersonPopUp: true, selectAllProducts: false });

		if (delegate.products.filter(item => item !== 9).length >= 9) {
			this.setState({ selectAllProducts: true })
		}
	}
	handleChange(event, attribute) {
		var isChecked = event.target.checked;
		var item = event.target.value;
		this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
		this.setState({ delegated: _.extend({}, this.state.delegated, { [attribute]: _.trimStart(event.target.value) }) })
	}
	handleRulesEnabled(attribute) {
		this.setState({ delegated: _.extend({}, this.state.delegated, { [attribute]: !this.state.delegated.rulesEnabled }) });
	}
	handleProductsChange = (product) => {
		const { delegated } = this.state;
		const tempDelegated = { ...delegated };
		const products = tempDelegated.products;
		products.indexOf(product) !== -1 ? products.splice(products.indexOf(product), 1) : products.push(product);
		tempDelegated.products = products;

		if (delegated.products.filter(item => item !== 9).length >= 9) {
			this.setState({ selectAllProducts: true })
		}
		else {
			this.setState({ selectAllProducts: false })
		}
		this.setState({ delegated: { ...tempDelegated } });
	}
	
	onIndividualSearch = (onComplete) => {
		const { delegated } = this.state;
		this.loadingStart();
		const onFinish = (success, data) => {
			this.loadingFinish();
			if (success) {
				this.setState({
					delegated: {
						...delegated,
						firstName: data.firstName,
						lastName: data.lastName,
						impersonatorIdentityNumber: data.idNumber,
					}
				});
			}
			onComplete && onComplete();
		};
		const onError = () => {
			this.loadingFinish();
			this.setState({ errors: { impersonatorIdentityNumber: ['Individual not found on search.'] } }) // Assuming setErrors is defined somewhere else.
		};
		this.props.fetchIndiviualInfo(delegated.impersonatorIdentityNumber, onFinish, null, onError);
	};

	loadingStart = () => {
		this.setState({ isLoading: true })
	};

	loadingFinish = () => {
		this.setState({ isLoading: false })
	};

	onHandleSearch = () => {
		const onComplete = () => this.setState({ isSearchComplete: false });

		const onError = () => this.setState();
		//this.onIndividualSearch && this.onIndividualSearch(onComplete);
		this.onIndividualSearch(onComplete);
	};
	_resetError(attribute) {
		const { errors } = this.state;
		this.setState({ errors: _.extend({}, errors, { [attribute]: null }) })
	}
	_validateForm() {
		const { delegated } = this.state;
		const options = { fullMessages: false };

		const constraints = {
			firstName: { presence: true, length: { minimum: 1, message: 'required' } },
			lastName: { presence: true, length: { minimum: 1, message: 'required' } },
			impersonatorIdentityNumber: { presence: true, numericality: { onlyInteger: true }, length: { minimum: 13, message: '13 digit required' } },
			products: { length: { minimum: 1, message: 'Select min 1 access to' } }
		};
		const errors = validate(delegated, constraints, options)
		this.setState({ errors });
		return _.isEmpty(errors);
	}

	allowOnlyNumbers = (event) => {
		let { value } = event.target;
		// Replace characters that are not letters or spaces with an empty string
		value = value.replace(/[^0-9]/g, '');
		// Limit the input to a maximum of 13 numbers
		value = value.slice(0, 13);
		event.target.value = value;
	}

	handleSelectAllProducts = () => {
		const { delegated, selectAllProducts } = this.state;
		const { products } = this.props;

		if (selectAllProducts) {
			delegated.products = [];
		} else {
			delegated.products = products.filter(item => item.id !== 9).map(item => item.id);
		}

		this.setState(prevState => ({
			selectAllProducts: !prevState.selectAllProducts,
			delegated: { ...delegated },
		}));
	};

	onToggleDelegatedPopUp = () => {
		this.setState({ isSearchComplete: false, selectAllProducts: false, isEdit: true });
		this.setState({ delegated: { firstName: '', lastName: '', impersonatorIdentityNumber: '', products: [], rulesEnabled: false } });
		this.setState(prevState => ({ showDelegatedPersonPopUp: !prevState.showDelegatedPersonPopUp }));
	};
	onToggleDelegatedEditPopUp = () => {
		this.setState(prevState => ({ editShowDelegatedPersonPopUp: !prevState.editShowDelegatedPersonPopUp }));
	};
	_renderDelegatesTemplate(delegate) {
		return (
			<div className="row" style={{ margin: 0 }} >
				<div className="col-md-2 col-sm-2 col-xs-12">
					<label style={{ padding: '5px' }}>{delegate.impersonatorIdentityNumber}</label>
				</div>
				<div className="col-md-3 col-sm-3 col-xs-12">
					<label style={{ padding: '5px' }}>{delegate.firstName + ' ' + delegate.lastName}</label>
				</div>
				<div className="col-md-3 col-sm-3 col-xs-12">
					<label style={{ padding: '5px' }}>{delegate.rulesEnabled ? " Yes" : " No"}</label>
				</div>
				<div className="col-md-2 col-sm-2 col-xs-12" style={{ textAlign: 'center' }}>
					<ButtonGroup>
						<button
							className="btn btn-danger" style={{ width: 'auto', marginBottom: '10px' }}
							role="button"
							onClick={() => this.remove(delegate)}>
							<i className="fa fa-times" aria-hidden="true" /> Delete
						</button>
					</ButtonGroup>
				</div>
				<div className="col-md-2 col-sm-2 col-xs-12" style={{ textAlign: 'center' }}>
					<ButtonGroup>
						<button
							className="btn btn-primary" style={{ width: 'auto', marginBottom: '10px' }}
							role="button"
							// make this popup edit with delegate info.
							onClick={() => this.editDelegate(delegate)}>View / Edit
						</button>
					</ButtonGroup>
				</div>
				<hr />

			</div>
		);
	}
	_renderDelegateList() {
		const { delegatedList } = this.props;
		const hasDelegates = _.size(delegatedList) > 0;
		if (!hasDelegates) return null;
		return (
			<div>
				<div className="row" style={{ margin: 0 }}>
					<div className="col-md-2 col-sm-2 col-xs-12">
						<b style={{ padding: '5px' }}>ID Number</b>
					</div>
					<div className="col-md-3 col-sm-3 col-xs-12">
						<b style={{ padding: '5px' }}>Names</b>
					</div>
					<div className="col-md-3 col-sm-3 col-xs-12">
						<b style={{ padding: '5px' }}>Can Configure Rules?</b>
					</div>
					<div className="col-md-2 col-sm-2 col-xs-12" style={{ textAlign: 'center' }}>
						<b style={{ padding: '5px' }}>Delete Delegate</b>
					</div>
					<div className="col-md-2 col-sm-2 col-xs-12" style={{ textAlign: 'center' }}>
						<b style={{ padding: '5px' }}>Access Details</b>
					</div>
				</div>
				<hr />
				<div className="row" style={{ padding: 0 }}>
					<DataList
						className="ui-datalist-nobullets" style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: 0 }}
						itemTemplate={this._renderDelegatesTemplate.bind(this)}
						paginator={false}
						rows={5}
						value={delegatedList}
					/>
				</div>
			</div>
		)
	}
	_renderDelegatedPopUp(delegate) {
		const { loading, products } = this.props;
		const { delegated, errors, showDelegatedPersonPopUp, isRSACitizen, isSearchComplete } = this.state;
		return (
			<Modal show={showDelegatedPersonPopUp} onHide={this.onToggleDelegatedPopUp} bsSize={'lg'}>
				<Modal.Body>
					<div>
						<div className="product-heading">Delegate Information</div>
						<div className="title-gradient" />
						<br />
						<div className="row" style={{ margin: 0 }}>
							<div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
								<Toggler
									label="South African Citizen / Resident?"
									isOn={isRSACitizen}
									onToggle={this.handleChangeSACitizen}
								/>
							</div>
						</div>
					</div>
					<DelegateDetailsCard
						errors={errors}
						delegated={delegated}
						products={products}
						history={this.props.history}
						loading={loading}
						onChange={this.handleChange}
						onProductChange={this.handleProductsChange}
						onResetError={this.resetError}
						handleRulesEnabled={this.handleRulesEnabled}
						onSubmit={this.handleAddDelegate}
						shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
						onSearch={this.onHandleSearch}
						allowOnlyNumbers={this.allowOnlyNumbers}
						selectAllProducts={this.state.selectAllProducts}
						handleSelectAllProducts={this.handleSelectAllProducts}
					/>
				</Modal.Body>
			</Modal>
		);
	}
	_renderEditDelegatedPopUp() {
		const { loading, products, isSearchComplete } = this.props;
		const { errors, editShowDelegatedPersonPopUp } = this.state;
		return (
			<Modal show={editShowDelegatedPersonPopUp} onHide={this.onToggleDelegatedEditPopUp}>
				<Modal.Body>
					<div>
						<div className="product-heading">
							Edit Delegate Information
						</div>
						<div className="title-gradient" />
					</div>
					<br />
					<DelegateDetailsCard
						errors={errors}
						delegated={this.state.delegated}
						products={products}
						history={this.props.history}
						loading={loading}
						onChange={this.handleChange}
						onProductChange={this.handleProductsChange}
						onResetError={this.resetError}
						handleRulesEnabled={this.handleRulesEnabled}
						onSubmit={this.handleEditDelegate}
						isEdit={this.state.isEdit}
						allowOnlyNumbers={this.allowOnlyNumbers}
						selectAllProducts={this.state.selectAllProducts}
						handleSelectAllProducts={this.handleSelectAllProducts}
					/>
				</Modal.Body>
			</Modal>
		)
	}
	render() {
		const { loading } = this.props;
		if (loading) return null;
		return (
			<div className="page-container">
				<div className="page-main-section" style={{ marginTop: 0 }}>
					<div className="card-container-form">
						<div className="section-title">
							<span>Delegated Persons</span>
						</div>
						{this._renderDelegateList()}
						<a className="flat-button-primary-light" onClick={this.onToggleDelegatedPopUp}
						>Add Delegate</a>
					</div>
				</div>
				{this._renderDelegatedPopUp()}
				{this._renderEditDelegatedPopUp()}
			</div>
		);
	}
}
class DelegateDetailsCard extends Component {
	_renderFooter() {
		const { onSubmit } = this.props;
		return (
			<div className="action-button-container" style={{ width: "100%" }}>
				<div />
				<button
					className="action-btn-secondary"
					onClick={onSubmit}
					type="button">Save
				</button>
			</div>
		)
	}
	render() {
		const { errors, delegated, handleRulesEnabled, onChange, onResetError, products, onSearch, onProductChange, isEdit = true, shouldDisableInput, allowOnlyNumbers, selectAllProducts, handleSelectAllProducts } = this.props;
		return (
			<div>
				<div className="row" style={{ margin: 0, padding: '10px 0' }}>
					<div className="col-md-6" >
						{!shouldDisableInput ? (
							<FormField
								className={"form-group"}
								id="od"
								error={errors && errors.impersonatorIdentityNumber}
							>
								<label htmlFor="impersonatorIdentityNumber">
									ID/Passport Number
								</label>
								<input
									// disabled={isEdit}
									className="form-control"
									name="impersonatorIdentityNumber"
									onChange={(event) => {
										allowOnlyNumbers(event);
										errors &&
											errors.impersonatorIdentityNumber &&
											onResetError("impersonatorIdentityNumber");
										onChange(event, "impersonatorIdentityNumber");
									}}
									type="text"
									value={delegated && delegated.impersonatorIdentityNumber}
								/>
							</FormField>
						) : (
							<FormField
								className={"form-group"}
								id="od"
								error={errors && errors.impersonatorIdentityNumber}
							>
								{/* <input
                                 disabled={isEdit}
                                 className="form-control"
                                 name="impersonatorIdentityNumber"
                                 maxLength={13}
                                 minLength={13}
                                 onChange={(event) => {
                                     errors && errors.impersonatorIdentityNumber && onResetError('impersonatorIdentityNumber');
                                     onChange(event, 'impersonatorIdentityNumber')
                                 }}
                                 type="text"
                                 value={delegated && delegated.impersonatorIdentityNumber}
                             /> */}
								<SearchComponent
									label="ID Number"
									onChange={(event) => {
										allowOnlyNumbers(event);
										errors &&
											errors.impersonatorIdentityNumber &&
											onResetError("impersonatorIdentityNumber");
										onChange(event, "impersonatorIdentityNumber");
									}}
									onSearch={onSearch}
									searchValue={delegated.impersonatorIdentityNumber || ""}
								/>
							</FormField>
						)}
						<FormField
							className={"form-group"}
							id="firstName"
							error={errors && errors.firstName}
						>
							<label htmlFor="tradingAs">Name</label>
							<input
								className="form-control"
								name="firstName"
								disabled={isEdit && shouldDisableInput}
								value={delegated && delegated.firstName}
								onChange={(event) => {
									errors && errors.firstName && onResetError("firstName");
									onChange(event, "firstName");
								}}
								type="text"
							/>
						</FormField>
						<FormField
							className={"form-group"}
							id="lastName"
							error={errors && errors.lastName}
						>
							<label htmlFor="lastName">Surname</label>
							<input
								type="text"
								className="form-control"
								name="lastName"
								disabled={isEdit && shouldDisableInput}
								value={delegated && delegated.lastName}
								onChange={(event) => {
									errors && errors.lastName && onResetError("lastName");
									onChange(event, "lastName");
								}}
							/>
						</FormField>
						<div>
							<label
								className="checkbox-inline"
								onClick={() => handleRulesEnabled("rulesEnabled")}
							>
								<i
									className={
										delegated && delegated.rulesEnabled
											? "fa fa-check-square-o"
											: "fa fa-square-o"
									}
									style={{
										color: "rgb(0, 51, 170)",
										fontSize: 18,
										marginRight: 10,
									}}
								/>
								Can Configure Rules?
							</label>
						</div>
					</div>
					<div className="col-md-6" >
						<FormField
							className="checkList"
							error={errors && errors.products}
						>
							<div className="title row">
								<label>Access to:</label>
								<input
									type="checkbox"
									checked={selectAllProducts}
									onChange={handleSelectAllProducts}
								/>{' '}
								Select All
							</div>
							<div className="list-container">
								{products
									.filter(item => item.id !== 9)
									.map((item) => (
										<div key={item.id}>
											<input
												id={"product-" + item.id}
												name="products"
												value={item.id}
												type="checkbox"
												//Written checkbox assign logic, but not yet working properly
												checked={delegated.products.includes(item.id)}
												onChange={(event) => {
													errors &&
														errors.products &&
														onResetError("products");
													onProductChange(item.id);
												}}
											/>
											<span> {item.name}</span>
										</div>
									))}
							</div>
						</FormField>
					</div>
				</div>
				<div className="row" style={{ margin: 0, padding: '10px 0' }}>
					{this._renderFooter()}
				</div>
			</div>
		);
	}
}
function mapStateToProps({ delegatedList, product: { products }, loading }, ownProps) {
	return {
		delegatedList: delegatedList && delegatedList.delegatedList ? delegatedList.delegatedList : [],
		products,
		loading,
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		fetchIndiviualInfo: (impersonatorIdentityNumber, onFinish, onFail, onError) => {
			dispatch(fetchIndiviualInfo(impersonatorIdentityNumber, onFinish, onFail, onError));
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DelegateAuthorityPage);
