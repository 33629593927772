import React from 'react';

const SECTIONS = {
    container: "container",
    mainContent: "main-content",
    closeBtn: "closeBtn"
};

export default class Popup extends React.Component {
    handleOnClose = (event) => {
        event.preventDefault();
        const {id} = event.target;
        if (id === SECTIONS.container || id === SECTIONS.closeBtn) {
            const {onClose} = this.props;
            onClose && onClose();
        }
    };

    render() {
        return (
            <div id={SECTIONS.container} className="popup-container" onClick={this.handleOnClose}>
                <div id={SECTIONS.mainContent} className="popup-main-section">
                    <i id={SECTIONS.closeBtn} className="fa fa-times popup-close-btn"/>
                    <div className="popup-scroller" onClick={(event) => event.stopPropagation()}
                         style={{width: "100%", maxHeight: '40em', overflowY: 'scroll'}}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}