import React, { useState, useEffect ,useRef} from 'react';
import { DataList } from 'primereact/components/datalist/DataList';
import { ListSearchComponent } from "../SearchComponent";
import { getAuthRules } from '../../actions/rules';
import {Glyphicon, OverlayTrigger,  Tooltip} from 'react-bootstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

const ErrorLabel = ({ error }) => (
    <div>
        <span className="form-error">{error}</span>
    </div>
);

export const SelectiveRulesPage = (props) => {

    const {updateLevelDataList, updateauthorisers, authorisers, selectedAuthorizers, result1, result2, renderFilteredList, renderList, goldTierId, errormsg } = props;
    const [selected, setSelected] = useState("No");
    const [countA, setCountA] = useState(0);
    const [countB, setCountB] = useState(0);
    const [levelData, setLevelData]= useState([]);
    const [filteredResult1, setFilteredResult1] = useState([]);
    const [filteredResult2, setFilteredResult2] = useState([]);
    const inputRef = useRef(null);

    const onFilter = (value) => {
        const filtered1 = result1.filter((item) =>
            item.name
                ? item.name.toLowerCase().includes(value.toLowerCase())
                : `${item.firstName} ${item.surname}`.toLowerCase().includes(value.toLowerCase())
        );

        const filtered2 = result2.filter((item) =>
            item.name
                ? item.name.toLowerCase().includes(value.toLowerCase())
                : `${item.firstName} ${item.surname}`.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredResult1(filtered1);
        setFilteredResult2(filtered2);
    };


    const handleSelectedChange = (e) => {
        setSelected(e.target.value);
        console.log(goldTierId);
        props.updateSelection(e.target.value);
    };

    const statementTooltip = (

        <Tooltip id="tooltip" className ="tooltip">
            <strong>No: </strong>
                According to the authorisation rules, SMS/s will be sent to AP's registered on Service Online
        </Tooltip>

    );

    const messageTooltip = (

        <Tooltip id="tooltip" className ="tooltip">
                Unregistered Authorized Persons are unable to receive approval requests and are not available as a selection option.
        </Tooltip>

    );

    const apicall = () => {
        const AUTH_RULES_CONFIG_URL = API_ROOT + '/api/authorisation/' + goldTierId;
        axios.get(AUTH_RULES_CONFIG_URL)
            .then((response) => {
                const rules = response.data.data.rules;
                const levelDataList = [];
                let Counter = 1;
                rules.forEach((rule) => {
                 const levelCount = {
                 countOfA : 0,
                 countOfB : 0
                 }
                rule.levels.forEach((level) => {

                    if (level.level === 'A') {
                        levelCount.countOfA += level.count;
                    } else if (level.level === 'B') {
                        levelCount.countOfB += level.count;
                    }

                });
                 levelDataList.push({ [`level${Counter}`]: levelCount})
                 Counter++;
                console.log(levelDataList);
                });
                setLevelData(levelDataList);
                const authorizers = response.data.data.authorisers;
                props.updateLevelDataList(levelDataList);
                props.updateauthorisers(authorizers);

            });
    };

    useEffect(() => {
        apicall();

    }, []);

    return (

        <div className="row" style={{ margin: '0', paddingBottom:'2em'}}>
            <div className="row" style={{ margin: '0' }}>

                <div className="section-title">Please select Approvers to approve the SMS notification request </div>
                <div className="form-group">
                    <div className="row">
                        <div ref = {inputRef}/>
                        <label className="col-md-6 control-label" style={{ paddingTop: '10px' }}> Do you want to select the approvers?</label>
                        <div className="col-md-2">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="Yes"
                                        checked={selected === 'Yes'}
                                        onChange={handleSelectedChange}
                                    />
                                    Yes
                                </label>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="No"
                                        checked={selected === 'No'}
                                        onChange={handleSelectedChange}
                                    />
                                    No
                                </label>
                            </div>
                            <label style={{marginLeft:'10px', left:'6px', paddingTop: '1px'}}>
                                <OverlayTrigger placement='right' overlay={statementTooltip}>
                                    <Glyphicon glyph="question-sign"/>
                                </OverlayTrigger>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {selected === 'No' &&
                <div className="row" style={{ margin: '0' }}>
                    <div className="section-title">The following approval requests will be sent</div>

                    <DataList
                        itemTemplate={renderList.bind(this)}
                        value={authorisers}

                    />
                </div>
            }

            {selected === 'Yes' &&
                <div className="row" style={{ margin: '0' }}>
                    <div className="section-title col-md-16">Please select the approvers according to any of the rule below
                        <span style={{marginLeft:'10px', left:'6px', paddingTop: '1px'}}>
                            <OverlayTrigger placement='right' overlay={messageTooltip}>
                                <Glyphicon glyph="question-sign"/>
                            </OverlayTrigger>
                        </span>

                        <div>
                            {levelData.map((level, index) => {
                                const counts = Object.values(level)[0];

                                let message;
                                if (counts.countOfA > 0 && counts.countOfB > 0) {
                                    message = `A combination of: ${counts.countOfA} of A, ${counts.countOfB} of B`;
                                } else if (counts.countOfA > 0) {
                                    message = `Any ${counts.countOfA} of A authoriser(s)`;
                                } else if (counts.countOfB > 0) {
                                    message = `Any ${counts.countOfB} of B authoriser(s)`;
                                }

                                return (
                                    <div key={index}>
                                        <p style={{color: '#A52A2A',fontSize: '0.8em'}}>
                                            {index + 1} .  {message}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>


                        {errormsg &&
                            <span className="col-md-offset-8"><ErrorLabel error={`Please select as per the auth rule mentioned above`} />
                            </span>}
                        {errormsg && inputRef.current.scrollIntoView({block : 'nearest',behaviour : 'smooth'})}

                    </div>

                    <div className="row" style={{ margin: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ListSearchComponent
                                onFilter={onFilter}
                                placeholder="Find Approver..."
                            />
                        </div>
                        <hr />

                        <div className="col-lg-6">
                            <label> Level A </label>
                            <hr />
                            <div className="row" style={{ margin: 0 }}>
                                <DataList
                                    itemTemplate={renderFilteredList.bind(this)}
                                    value={filteredResult1.length > 0 ? filteredResult1 : result1}
                                />
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <label> Level B </label>
                            <hr />
                            <div className="row" style={{ margin: 0 }}>
                                <DataList
                                    itemTemplate={renderFilteredList.bind(this)}
                                    value={filteredResult2.length > 0 ? filteredResult2 : result2}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};


export default SelectiveRulesPage;