export const CREDIT_APPLICATION_COMPLETE_SUCCESS = 'CREDIT_APPLICATION_COMPLETE_SUCCESS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_ANNOUNCEMENT_SUCCESS = 'LOAD_ANNOUNCEMENT_SUCCESS';
export const LOAD_IMPORTANT_NOTICE_SUCCESS = 'LOAD_IMPORTANT_NOTICE_SUCCESS'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const APPLICATION_UPDATED = 'APPLICATION_UPDATED';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';
export const ENTITY_SELECTED = 'ENTITY_SELECTED';
export const FECTH_HIERACHY_SUCCESS = 'FECTH_HIERACHY_SUCCESS';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const CLEAR_SYSTEM_ERROR = 'CLEAR_SYSTEM_ERROR';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_EMPTY = 'FETCH_ACCOUNTS_EMPTY';
export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';
export const FECTH_ENTITY_SUCCESS = 'FECTH_ENTITY_SUCCESS';
export const FECTH_INDIVIDUAL_SUCCESS = 'FECTH_INDIVIDUAL_SUCCESS';
export const CLEAR_INDIVIDUAL_INFO = 'CLEAR_INDIVIDUAL_INFO';
export const SEARCH_ENTITY_SUCCESS = 'SEARCH_ENTITY_SUCCESS';
export const ADD_KYC_CHANGE_SUCCESS = 'ADD_KYC_CHANGE_SUCCESS';
export const ENTITY_UPDATE_SUCCESS = 'ENTITY_UPDATE_SUCCESS';
export const ACCOUNT_CONFIRM_SUCCESS = 'ACCOUNT_CONFIRM_SUCCESS';
export const LOAD_USER_APPLICATIONS_SUCCESS = 'LOAD_USER_APPLICATIONS_SUCCESS';
export const LOAD_SELECTED_APPLICATION_SUCCESS = 'LOAD_SELECTED_APPLICATION_SUCCESS';
export const SAVE_USER_APPLICATIONS_SUCCESS = 'SAVE_USER_APPLICATIONS_SUCCESS';
export const GO_PREVIOUS = 'GO_PREVIOUS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const DOC_VERIFY_SUCCESS = 'DOC_VERIFY_SUCCESS';
export const DOC_UPLOAD_SUCCESS = 'DOC_UPLOAD_SUCCESS';
export const DOC_UPDATE_SUCCESS = 'DOC_UPDATE_SUCCESS';
export const NEW_ENTITY_DOC_UPDATE_SUCCESS = 'NEW_ENTITY_DOC_UPDATE_SUCCESS';
export const RELATED_DOC_UPLOAD_SUCCESS = 'RELATED_DOC_UPLOAD_SUCCESS';
export const RELATED_PARTY_SUCCESS = 'RELATED_PARTY_SUCCESS';
export const ADD_SIGNATORY_SUCCESS = 'ADD_SIGNATORY_SUCCESS';
export const REMOVE_SIGNATORY_SUCCESS = 'REMOVE_SIGNATORY_SUCCESS';
export const FETCH_SIGNATORY_FOR_ACC_SUCCESS = 'FETCH_SIGNATORY_FOR_ACC_SUCCESS';
export const ADD_NEW_ENTITY = 'ADD_NEW_ENTITY';
export const EDIT_NEW_ENTITY = 'EDIT_NEW_ENTITY';
export const APP_CONFIRM_SUCCESS = 'APP_CONFIRM_SUCCESS';
export const FETCH_AUTHORISERS = 'FETCH_AUTHORISERS';
export const VIEW_MENU_OPTION = 'VIEW_MENU_OPTION';
export const VIEW_DASH_BOARD = 'VIEW_DASH_BOARD';
export const FETCH_DELEGATES_SUCCESS = 'FETCH_DELEGATES_SUCCESS';
export const FETCH_IMPERSONATIONS_SUCCESS = 'FETCH_IMPERSONATIONS_SUCCESS';
export const START_IMPERSONATION = 'START_IMPERSONATION';
export const STOP_IMPERSONATION = 'STOP_IMPERSONATION';
export const FETCH_AUTH_RULES_SUCCESS = 'FETCH_AUTH_RULES_SUCCESS';
export const SELECT_DIRECTOR_AUTH_LEVEL = 'SELECT_DIRECTOR_AUTH_LEVEL';
export const ADD_AUTH_RULE = 'ADD_AUTH_RULE';
export const REMOVE_AUTH_RULE = 'REMOVE_AUTH_RULE';
export const SAVE_AUTH_RULES_SUCCESS = 'SAVE_AUTH_RULES_SUCCESS';
export const SAVE_AUTH_RULES_ERROR = 'SAVE_AUTH_RULES_ERROR';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';
export const REMOVE_SEARCH_RESULTS = 'REMOVE_SEARCH_RESULTS';
export const ON_CHANGE_ENTITY_VALUE = 'ON_CHANGE_ENTITY_VALUE';
export const NEW_ENTITY_SEARCH_SUCCESS = 'NEW_ENTITY_SEARCH_SUCCESS';
export const SAVE_NEW_ENTITY_INFO_SUCCESS = 'SAVE_NEW_ENTITY_INFO_SUCCESS';
export const ONBOARD_NEW_ENTITY_SUCCESS = 'ONBOARD_NEW_ENTITY_SUCCESS';
export const FETCH_ENTITIES_ONBOARD_REQUESTS_SUCCESS = 'FETCH_ENTITIES_ONBOARD_REQUESTS_SUCCESS';
export const FETCH_ENTITY_ONBOARD_REQUEST_SUCCESS = 'FETCH_ENTITY_ONBOARD_REQUEST_SUCCESS';
export const UPDATE_COPIES = 'UPDATE_COPIES';
export const CLEAR_COPIES = 'CLEAR_COPIES';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FETCH_ACCOUNT_TRANSACTIONS_SUCCESS = 'FETCH_ACCOUNT_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const SAVE_NEW_BOL_PROFILE_SUCCESS = 'SAVE_NEW_BOL_PROFILE_SUCCESS';
export const ON_CHANGE_BOL_VALUE = 'ON_CHANGE_BOL_VALUE';
export const FETCH_BOL_PROFILES_SUCCESS = 'FETCH_BOL_PROFILES_SUCCESS';
export const FETCH_BOL_OPERATORS_SUCCESS = 'FETCH_BOL_OPERATORS_SUCCESS';
export const FETCH_BOL_SUCCESS = 'FETCH_BOL_SUCCESS';
export const CLEAR_BOL_PROFILE_OPERATORS = 'CLEAR_BOL_PROFILE_OPERATORS';
export const START_CREDIT_APPLICATION = 'START_CREDIT_APPLICATION';
export const CLEAR_CREDIT_APPLICATION = 'CLEAR_CREDIT_APPLICATION';
export const SHOW_RESPONSE_ERROR = 'SHOW_RESPONSE_ERROR';
export const CLEAR_RESPONSE_ERROR = 'CLEAR_RESPONSE_ERROR';
export const FETCH_CUSTODY_DATA = 'FETCH_CUSTODY_DATA';
export const ON_CHANGE_CUSTODY_VALUE = 'ON_CHANGE_CUSTODY_VALUE';
export const ON_ADD_CASH_ACCOUNT_FOR_CUSTODY = 'ON_ADD_CASH_ACCOUNT_FOR_CUSTODY';
export const GM_START_APPLICATION = 'GM_START_APPLICATION';
export const GM_UPDATE_APPLICATION = 'GM_UPDATE_APPLICATION';
export const GM_COMPLETE_APPLICATION = 'GM_COMPLETE_APPLICATION';
export const GM_FETCH_APPLICATION = 'GM_FETCH_APPLICATION';
export const GM_FETCH_CUSTOMERS = 'GM_FETCH_CUSTOMERS';
export const GM_FETCH_INSTRUCTIONS = 'GM_FETCH_INSTRUCTIONS';
export const GM_UPDATE = 'GM_UPDATE';
export const SUPPORT_SEARCH = 'SUPPORT_SEARCH';
export const CUSTODY_APPLICATION_SUCCESS = 'CUSTODY_APPLICATION_SUCCESS';
export const ON_BOL_VALUE_CHANGE = 'ON_BOL_VALUE_CHANGE';
export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const KY_START = 'KY_START';
export const KY_CONTINUE = 'KY_CONTINUE';
export const KY_CHANGE = 'KY_CHANGE';
export const KY_ENTITY = 'KY_ENTITY';
export const KY_DIR_CHANGE = 'KY_DIR_CHANGE';
export const KY_ADDRESS_CHANGE = 'KY_ADDRESS_CHANGE';
export const KY_DOC = 'KY_DOC';
export const KY_RELATED = 'KY_RELATED';
export const KY_RM_RELATED = 'KY_RM_RELATED';
export const KY_RM_AUTHORISED_PERS = 'KY_RM_AUTHORISED_PERS';
export const KY_AUTHORISED_PERSON = 'KY_AUTHORISED_PERSON';
export const KY_SAVED = 'KY_SAVED';
export const KY_ADD_OWNER = 'KY_ADD_OWNER';
export const KY_PIP = 'KY_PIP';
export const KY_UPDATE_PIPS = 'KY_UPDATE_PIPS';
export const KY_UPDATE_CONTROLLER = 'KY_UPDATE_CONTROLLER';
export const START_BOL_ONBOARD_SUCCESS = 'START_BOL_ONBOARD_SUCCESS';
export const ONBOARING_DOC = 'ONBOARING_DOC';
export const TPFA_START = 'TPFA_START';
export const TPFA_SAVE = 'TPFA_SAVE';
export const TP_ADDITIONALPRODUCT = 'TP_ADDITIONALPRODUCT';
export const TP_CONTINUE = 'TP_CONTINUE';

export const SM_START = 'SM_START';
export const SM_APPS = 'SM_APPS';
export const SM_ENTITY = 'SM_ENTITY';

export const LR_SELECT_ENTITY = 'LR_SELECT_ENTITY';
export const LR_START = 'LR_START';
export const LR_ACCOUNTS = 'LR_ACCOUNTS';
export const LR_PROFILES = 'LR_PROFILES';
export const LR_ENTITIES = 'LR_ENTITIES';
export const LR_DOC = 'LR_DOC';
export const LR_CHANGE = 'LR_CHANGE';
export const TRACKING = 'tracking';
export const TRACKING_DATA = 'tracking_data';
export const TRACKING_SUBMIT = 'tracking_submit';
export const LOGIN_TRACKING = 'LOGIN_TRACKING';
export const START_APPLICATION = 'START_APPLICATION';
export const COMPLETE_APPLICATION = 'COMPLETE_APPLICATION';
export const FETCH_CASH_MANAGED_FUND_DATA = 'FETCH_CASH_MANAGED_FUND_DATA';
export const FETCH_CUSTODY_MANAGED_FUND_DATA = 'FETCH_CUSTODY_MANAGED_FUND_DATA';
export const FETCH_CASH_CUSTODY_MANAGED_FUND_DATA = 'FETCH_CASH_CUSTODY_MANAGED_FUND_DATA';
export const FETCH_AUTHORISED_PEOPLE_NAMES_DATA = 'FETCH_AUTHORISED_PEOPLE_NAMES_DATA';
export const FETCH_ELECTRONICINDEMNITY_DATA = 'FETCH_ELECTRONICINDEMNITY_DATA';
export const ELECTRONICINDEMNITY_APPLICATION_SUCCESS = 'ELECTRONICINDEMNITY_APPLICATION_SUCCESS';
export const FETCH_SHARIAH_BCA_DATA = 'FETCH_SHARIAH_BCA_DATA';
export const FETCH_SAVINGS_DATA = 'FETCH_SAVINGS_DATA';

export const FETCH_SIGNATORY_APPLICATION_SUCCESS = 'FETCH_SIGNATORY_APPLICATION_SUCCESS';
export const FETCH_SIGNATORY_APPLICATION_FAILURE = 'FETCH_SIGNATORY_APPLICATION_FAILURE';
