import axios from 'axios';
import {saveAs} from 'file-saver';
import {API_ROOT} from '../api-config';
import * as types from './actionTypes';
import {authCheck} from './appLoading';

const DOWNLOAD_URL = `${API_ROOT}/api/reportdownload`;
const EMAIL_URL = `${API_ROOT}/api/reportemail`;

export function downloadReport(reportName,requestBody, onComplete = null, showError = false) {
    return async dispatch => {
        try {
           // enableLoading &&
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${DOWNLOAD_URL}?reportName=${reportName}`;

            const responseData = await axios.post(url,requestBody).then(response => response.data);

            if (responseData.success) {
                const {body, headers} = responseData.data;
                try {
                    const binary = atob(body.replace(/\s/g, ''));
                    const len = binary.length;
                    const buffer = new ArrayBuffer(len);
                    const view = new Uint8Array(buffer);

                    _.map(_.range(0, len), i => view[i] = binary.charCodeAt(i));

                    const blob = new Blob([view], { type: headers['Content-Type'][0]});
                    saveAs(blob, `${reportName}.pdf`);
                } catch (err){
                    console.log('actions.reports.downloadReport.err ===>');
                }
                onComplete && onComplete(true);
            } else {
                console.log('actions.reports.downloadReport.errorMessage ===> ', responseData.message);
                onComplete && onComplete();
                showError  && dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  'Couldn\'t download report.'});
            }
        } catch (error) {
            onComplete && onComplete();
            console.log('actions.reports.downloadReport.error ===> ', error);
            authCheck(dispatch,error);
        }
    };
}

export function emailReport(reportName, requestBody, onComplete = null) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${EMAIL_URL}?reportName=${reportName}`;
            const responseData = await axios.post(url, requestBody).then(response => response.data);
            if (responseData.success) {
                onComplete && onComplete(true);
            } else {
                onComplete && onComplete();
                console.log('actions.reports.emailReport.errorMessage ===> ', responseData.message);
            }
        } catch (error) {
            onComplete && onComplete();
            console.log('actions.reports.emailReport.error ===> ', error);
            authCheck(dispatch,error);
        }
    };
}
