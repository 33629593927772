import React from 'react';
import _ from 'lodash';
import {ListSearchComponent} from '../SearchComponent';
import moment from 'moment';


const AccountsList = (props) => {
    const {accounts, filteredAccounts , onSelect, onSelectAll, onSelectRadio, onFilterAccounts, isMultiSelect, selectedAcc, listStyle, hideDate, hasShortName, canSelectAll} = props;
    if (!accounts) return null;
    // const groupedAccs = filteredAccounts ? filteredAccounts : accounts;
    const groupedAccs = filteredAccounts || accounts;
    const notAllSelected = _.size(_.find(groupedAccs, accs => accs[0] && !accs[0].isSelected)) > 0;
    const tstamp = new Date().getTime();

    if (isMultiSelect) {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    marginBottom: 5,
                    justifyContent: 'space-between'
                }}>
                    {canSelectAll && <div
                        className="selection-list-item"
                        onClick={(e) => onSelectAll(notAllSelected)}>
                        <i
                            className={!notAllSelected ? 'fa fa-check-circle' : 'fa fa-circle-o'}
                            style={{color: 'rgb(0, 51, 170)', fontSize: 20, marginRight: 10}}
                        />
                        <span className="active-entity" style={{marginLeft: 10}}>Select All</span>
                    </div> }
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <ListSearchComponent
                            onFilter={onFilterAccounts}
                            placeholder="Find account..."
                        />

                        {false && <i className="fa fa-info-circle" style={{marginLeft: 10, fontSize: 16}}/>}
                    </div>
                </div>
                <div className="selection-list" style={listStyle ? listStyle : {maxHeight: '43vh'}}>
                    {
                        _.map(groupedAccs, (accs, i) => {

                                const isSelected = (accs.isSelected || (selectedAcc && selectedAcc.accountNumber === accs.accountNumber));
                                return (
                                    <div
                                        className={isSelected ? 'selection-list-item-selected' : 'selection-list-item'}
                                        key={`accs-${i}`}
                                        onClick={(e) => onSelect(e, accs)}
                                    >
                                        <div className="icon-container">
                                            <i
                                                className={(isSelected ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                                style={{fontSize: 20}}
                                            />
                                        </div>
                                        <div className="column">
                                            {
                                                    <div key={`acc-${accs.accountNumber}-${i}-${tstamp}`}>
                                                        <span>{accs.name} | </span>
                                                        <span
                                                            className="greyText"> {accs.accountNumber}
                                                            {!hideDate && ('| ' + moment(accs.accountOpenDate).format('MMMM D, YYYY'))}
                                                        </span>
                                                        <span>
                                                            {hasShortName && accs.shortName &&  '| ' + accs.shortName}
                                                        </span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                );
                            }
                        )
                    }
                </div>
            </div>
        );
    } else {
        return (

            <div>
                <div style={{display: 'flex', alignItems: 'center', padding: 2, justifyContent: 'space-between'}}>
                    <div/>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <ListSearchComponent
                            onFilter={onFilterAccounts}
                            placeholder="Find account..."
                        />

                        {false && <i className="fa fa-info-circle" style={{marginLeft: 10, fontSize: 16}}/>}
                    </div>
                </div>
                <fieldset className="selection-list" style={listStyle ? listStyle : {maxHeight: '43vh'}}
                          name="accounts">
                    {
                        _.map(groupedAccs, (accs, i) => {
                            const isSelected = ((selectedAcc && selectedAcc.accountNumber === accs.accountNumber));
                                return (
                                    <div
                                        className={isSelected ? 'selection-list-item-selected' : 'selection-list-item'}
                                        onClick={(e) => onSelectRadio(accs)}
                                        key={`accs/${i}/${tstamp}`}
                                    >
                                        <i
                                            className={isSelected ? 'fa fa-check-circle' : 'fa fa-circle-o'}
                                            style={{color: 'rgb(0, 51, 170)', fontSize: 20, marginRight: 10}}
                                        />
                                        <div className="column">
                                            {
                                                <div key={`acc/${accs.accountNumber}/${i}/${tstamp}`}>
                                                    <span>{accs.name} | </span>
                                                    <span
                                                        className="greyText"> {accs.accountNumber} {!hideDate && ('| ' + moment(accs.accountOpenDate).format('MMMM D, YYYY'))}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            }
                        )
                    }
                </fieldset>
            </div>
        );
    }
};

export default AccountsList;
