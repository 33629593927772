import _ from 'lodash';

export const newAccountOpeningRoutes = {
    newAccount: '/new-account',
    entity: '/new-account/entity',
    confirmdetails: '/new-account/confirmdetails',
    confirmproduct: '/new-account/confirmproduct',
    kyc: '/new-account/kyc',
    confirmauthorisers: '/new-account/confirmauthorisers',
    confirmapplication: '/new-account/confirmapplication',
    custodyaccount: '/new-account/custodyaccount'
};

export const managedFundRoutes = {
    // managed fund Cash Account routes
    mFtile: '/managedFund',
    cashAccount: '/managedFund/cashAccount',
    cashConfirmdetails:'/managedFund/cashAccount/confirmDetails',
    cashConfirmProductdetails:'/managedFund/cashAccount/productDetails',
    cashKyc: '/managedFund/cashAccount/kyc',
    cashConfirmAuthorisers: '/managedFund/cashAccount/confirmauthorisers',
    cashConfirmApplication: '/managedFund/cashAccount/confirmapplication',

    //managed fund Custody Account routes
    custodyAccount: '/managed-Fund/custody-Account',
    custodyAccountRelations: '/managed-Fund/custody-Account-Relations',
    custodyBeneficialOwnerDetails: '/managed-Fund/custody-Beneficial-Owner-Details',
    custodyAddressDetails:'/managed-Fund/custody-Address-Details',
    custodyContactDetails:'/managed-Fund/custody-Contact-Details',
    custodyCashAccountPurpose:'/managed-Fund/custody-Cash-Account-Purpose',
    custodyInstructingParty:'/managed-Fund/custody-Instructing-Party',
    custodyIsOnlineAndDocUpload:'/managed-Fund/custody-Is-Online-And-Document-Upload',    
    custodyConfirmAuthorizers:'/managed-Fund/custody-Confirm-Authorizers',
    custodyConfirmApplicationPage:'/managed-Fund/custody-Confirm-Application-Page',

    //managed fund Cash And Custody Account routes

    cashAndCustodyAccount:'/managed-fund-cash-and-custody/cash-And-Custody',
    cashAndCustodyConfirmdetails:'/managed-fund-cash-and-custody/Confirm-details',
    cashAndCustodyConfirmProduct:'/managed-fund-cash-and-custody/Confirm-Product',
    cashAndCustodyKyc: '/managed-fund-cash-and-custody/Kyc',
    cashAndCustodyCapacityType:'/managed-fund-cash-and-custody/Account-Capacity-Type',
    cashAndCustodyBeneficialOwnerDetails:"/managed-fund-cash-and-custody/Beneficial-Owner-Details",
    cashAndCustodyAddressDetails:"/managed-fund-cash-and-custody/Address-Details",
    cashAndCustodyContactDetails:"/managed-fund-cash-and-custody/Contact-Details",
    cashAndCustodyAccountPurpose:"/managed-fund-cash-and-custody/Account-Purpose",
    cashAndCustodyInstructingParty:"/managed-fund-cash-and-custody/Instructing-Party",
    cashAndCustodyConfirmAuthorisers:"/managed-fund-cash-and-custody/Confirm-Authorisers",
    cashAndCustodyConfirmApplication:"/managed-fund-cash-and-custody/Confirm-Application"
};

 //electronic indemnity

export const electronicIndemnityRoutes = {
    electronicIndemnity:'/electronicIndemnity',
    electronicIndemnityClauses:'/electronicIndemnity/electronicIndemnityClauses',
    authorisedIndividual:'/electronicIndemnity/authorisedIndividual',
    confirmAuthorisers:'/electronicIndemnity/confirmAuthorisers',
    completeElectronicIndemnity:'/electronicIndemnity/CompleteElectronicIndemnity'
};

// Shari’ah BCA
export const shariahBcaRoutes = {
    shariahBcaEntity:'/shariah-bca',
    shariahBcaConfirmDetails:'/shariah-bca/confirm-details',
    shariahBcaConfirmProduct:'/shariah-bca/confirm-product',
    shariahBcaKYC:'/shariah-bca/kyc',
    shariahBcaConfirmAuthorisers:'/shariah-bca/confirm-authorisers',
    shariahBcaComplete:'/shariah-bca/complete'
};

//Savings Account
export  const savingsAccountRoutes ={
    savingsAccountEntity: '/savings-account',
    savingsAccountConfirmDetails: '/savings-account/confirm-details',
    savingsAccountConfirmProduct: '/savings-account/confirm-product',
    savingsAccountKYC: '/savings-account/KYC',
    savingsAccountConfirmAuthorisers: '/savings-account/confirm-authorisers',
    savingsAccountComplete: '/savings-account/complete',
}


export const newBolProfileRoutes = {
    bol: '/bol',
    details: '/bol/details',
    channel: '/bol/channels',
    options: '/bol/options',
    accounts: '/bol/accounts',
    users: '/bol/users',
    complete: '/bol/complete'
};

export const businessOnlineRoutes = {
    businessOnline: '/business-online',
    transaction: '/business-online/transaction',
    accountSetup: '/business-online/accounts-setup',
    authorization: '/business-online/authorization',
    contactDetails: '/business-online/contact-details',
    complete: '/business-online/complete'
};

export const newCustodyAccountRoutes = {
    custodyaccount: '/custodyaccount',
    accountrelations: '/custodyaccount/accountrelations',
    beneficialowner: '/custodyaccount/beneficialowner',
    addressdetails: '/custodyaccount/addressdetails',
    contactdetails: '/custodyaccount/contactdetails',
    instructingparty: '/custodyaccount/instructingparty',
    complete: '/custodyaccount/complete',
    accountcashpurpose: '/custodyaccount/AccountcashpurposePage',
    confirmauthorisers: '/custodyaccount/confirmauthorisers',
};

const CHEQUE_PENDING_TASKS = {
    confirmEnitityDetails: 'UserTask_ConfirmEnitityDetails',
    confirmAccountDetails: 'UserTask_ConfirmAccountDetails',
    allConfirmed: 'UserTask_AllConfirmed'
};

export const globalMarketAccountRoutes = {
    entity: '/globalmarket/entity',
    confirmentity: '/globalmarket/confirmentity',
    //customers: "/globalmarket/customers",
    instructions: '/globalmarket/instructions',
    complete: '/globalmarket/complete'
};

export const digitalCreditRoutes = {
    root: '/credit',
    details: '/credit/details',
    complete: '/credit/complete',
};

export const maintenanceRoutes = {
    root: '/maintenance',
};

export const kycRoutes = {
    root: '/kyc',
    entityDetails: '/kyc/entity-details',
    peopleDetails: '/kyc/entity-people',
    documents: '/kyc/entity-documents',
    complete: '/kyc/complete'
};

export const remediationRoutes = {
    root: '/EBTC',
    legals: '/EBTC/agreements',
    complete: '/EBTC/Complete'
};

export const tpfaRoutes = {
    root: '/TPFA',
    thirdParty: '/TPFA/TP-Details',
    tpfaConfirmAuthorisers: '/TPFA/Confirm-Authorisers',
    complete: '/TPFA/Complete'
};

export const signatoryRoots = {
    root: '/signatory-maintenance',
    addStart: '/signatory-maintenance/add',
    addInstructions: '/signatory-maintenance/add/instructions',
    deleteStart: '/signatory-maintenance/delete',
    confirmDeleteAuthorizers: '/signatory-maintenance/delete/confirm',
    deleteInstructions: '/signatory-maintenance/delete/instructions',
    confirmAuthorizers: '/signatory-maintenance/confirm'
};

export const menuRoutes = {
    accountOpening: newAccountOpeningRoutes.newAccount,
    maintenance: signatoryRoots.root,
    newEntity: '/onboard',
    payments: '/payments',
    reporting: '/reporting',
    track: '/track',
    bol: '/bol',
    businessOnline: '/business-online',
    custody: '/custodyaccount',
    electronicIndemnity: '/electronicIndemnity',
    globalmarket: globalMarketAccountRoutes.entity,
    credit: digitalCreditRoutes.root,
    custodyaccount: newCustodyAccountRoutes.custodyaccount,
    kyc: kycRoutes.root,
    remediation: remediationRoutes.root,
    tpfa: tpfaRoutes.root,
    signatoryMaintenance: signatoryRoots.root
};


