import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import {
    menuRoutes,
    newBolProfileRoutes,
    newAccountOpeningRoutes,
    digitalCreditRoutes,
    maintenanceRoutes,
    newCustodyAccountRoutes,
    kycRoutes,
    businessOnlineRoutes,
    remediationRoutes,
    globalMarketAccountRoutes,
    tpfaRoutes, signatoryRoots,
    managedFundRoutes,
    electronicIndemnityRoutes,
    shariahBcaRoutes,
    savingsAccountRoutes

} from './routes';

import HomePage from '../components/HomePage';
import HierachyListPage from '../components/legalentity/HierachyListPage';
import EntityDetailsPage from '../components/legalentity/EntityDetailsPage';
import ConfirmProductPage from '../components/products/ConfirmProductPage';
import kyc from '../components/kyc/kyc';
import NavBar from '../components/Nav';
import requireAuth from '../components/LoggedIn';
import ConfirmApplicationPage from '../components/ConfirmApplicationPage';
import DelegateAuthorityPage from '../components/delegation/DelegateAuthorityPage';
import ConfigureRulesPage from '../components/rules/ConfigureRulesPage';
import RegulatoryInfoPage from '../components/legalentity/new entity/RegulatoryInfoPage';
import PageLoading from '../components/PageLoading';
import ConfirmAuthorisersPage from '../components/ConfirmAuthorisersPage';
import EntityLookupPage from '../components/legalentity/new entity/EntityLookupPage';
import NewEntityDetailsPage from '../components/legalentity/new entity/NewEntityDetailsPage';
import NewEntitykycPage from '../components/legalentity/new entity/NewEntitykycPage';
import OnBoardingPage from '../components/legalentity/new entity/OnBoardingPage';
import OnboardConfirmationPage from '../components/legalentity/new entity/OnboardConfirmationPage';
import ReportsPage from '../components/reports/ReportsPage';
import TrackingLandingPage from '../components/tracking/TrackingLandingPage';
import PaymentTrackingPage from '../components/tracking/PaymentTrackingPage';
import StepIndicatorNav from '../components/StepIndicatorNav';
import NewBolPage from '../components/bol/NewBolPage';
import ProfileProcessingOptionsPage from '../components/bol/ProfileProcessingOptionsPage';
import ChannelPage from '../components/bol/ChannelPage';
import BankAccountsPage from '../components/bol/BankAccountsPage';
import CustomerDetailsPage from '../components/bol/CustomerDetailsPage';
import UsersInfoPage from '../components/bol/UsersInfoPage';
import ApplicationCompletePage from '../components/bol/ApplicationCompletePage';
import DigitalCreditLandingPage from '../components/digitalCredit/DigitalCreditLandingPage';
import DigitalCreditPage from '../components/digitalCredit/DigitalCreditPage';
import CreditConfirmationPage from '../components/digitalCredit/CreditConfirmationPage';
import Footer from '../components/Footer';
import MaintenancePage from '../components/maintenance/MaintenancePage';
import SaveApplicationPage from '../components/SaveApplicationPage';
import ErrorMessage from '../components/ErrorMessage';
import BeneficialOwnerPage from '../components/bancs/BeneficialOwnerPage';
import CompletionPage from '../components/bancs/CompletionPage';
import ContactDetailsPage from '../components/bancs/ContactDetailsPage';
import AddressDetailsPage from '../components/bancs/AddressDetailsPage';
import InstructingPartyPage from '../components/bancs/InstructingPartyPage';
import AccountRelationsPage from '../components/bancs/AccountRelationsPage';
import AccountCashPurposePage from '../components/bancs/AccountCashPurposePage';
import NewCustodyAccount from '../components/bancs/NewCustodyAccount';
import KycMaintenance from '../components/kycMaintanence/KycMaintenance';
import SSICompletionPage from '../components/globalmarket/CompletionPage';
import ConfirmEntityPage from '../components/globalmarket/ConfirmEntityPage';
import SettlementInstructionsPage from '../components/globalmarket/SettlementInstructionsPage';
import CustomersPage from '../components/globalmarket/CustomersPage';
import LegalRemediation from '../components/legalRemediation/Legal-Remediation';
import Legals from '../components/legalRemediation/Legals';
import CustodyConfirmPage from '../components/bancs/CustodyConfirmPage';
import BusinessOnlinePage from '../components/businessOnline/BusinessOnlinePage';
import TransactionDetailsPage from '../components/businessOnline/TransactionDetailsPage';
import AuthorizationDetailsPage from '../components/businessOnline/AuthorizationDetailsPage';
import CompletePage from '../components/businessOnline/CompletePage';
import EbtcComplete from '../components/legalRemediation/EbtcComplete';
import TPFAStart from '../components/TPFA/TPFAStart';
import ThirdPartyDetails from '../components/TPFA/ThirdPartyDetails';
import TPFAConfirmAuthorisers from '../components/TPFA/TPFAConfirmAuthorisers';
import TPFAComplete from '../components/TPFA/CompletePage';
import TokenReceiver from '../components/TokenReceiver';
import AdditionalProducts from '../components/TPFA/AdditionalProducts';
import KycEntity from '../components/kycMaintanence/KycEntity';
import KycCompletePage from '../components/kycMaintanence/KycCompletePage';
import {AccountSetupPage} from '../components/businessOnline/AccountSetupPage';
import BolContactInfo from '../components/businessOnline/BolContactInfo';
import {PeoplePage} from '../components/kycMaintanence/PeoplePage';
import {DocumentsPage} from '../components/kycMaintanence/DocumentsPage';
import {SignApplications} from '../components/signatories/signApplications';
import {SignatoryReview} from '../components/signatories/SignatoryReview';
import {DeleteSignatory} from '../components/signatories/deleteSignatory';
import {SignatoryConfirmPage} from '../components/signatories/SignatoryConfirmPage';
import Logout from "../components/Logout";

import ManagedFundTiles from '../components/managedFund/managedFundTiles';
import CashAccount from '../components/managedFund/cashAccount/cashAccount';
import cashConfirmdetails from '../components/managedFund/cashAccount/cashConfirmdetails';
import cashConfirmProduct from '../components/managedFund/cashAccount/cashConfirmProduct';
import cashKyc from '../components/managedFund/cashAccount/cashKyc';
import cashConfirmAuthorisersPage from '../components/managedFund/mFundConfirmAuthorisersPage';
import cashConfirmApplicationPage from '../components/managedFund/mFundConfirmApplicationPage';
//import kyc from '../components/kyc/kyc';

//Managed funds custody routes
import custodyAccount from '../components/managedFund/custodyAccount/custodyAccount';
import custodyAccountRelations from '../components/managedFund/custodyAccount/custodyAccountRelations';
import custodyBeneficialOwnerDetails from '../components/managedFund/custodyAccount/custodyBeneficialOwnerDetails';
import custodyAddressDetails from '../components/managedFund/custodyAccount/custodyAddressDetails';
import custodyContactDetails from '../components/managedFund/custodyAccount/custodyContactDetails';
import custodyCashAccountPurpose from '../components/managedFund/custodyAccount/custodyCashAccountPurpose';
import custodyInstructingParty from '../components/managedFund/custodyAccount/custodyInstructingParty';
import custodyIsOnlineAndDocUpload from '../components/managedFund/custodyAccount/custodyIsOnlineAndDocUpload';
import custodyConfirmAuthorizers from '../components/managedFund/custodyAccount/custodyConfirmAuthorisers';
import custodyConfirmApplicationPage from '../components/managedFund/custodyAccount/custodyConfirmApplication';

//Managed funds cash and custody routes
import cashAndCustodyAccount from '../components/managedFund/cashAndCustody/cashAndCustody';
import cashAndCustodyConfirmdetails from '../components/managedFund/cashAndCustody/cashAndCustodyConfirmdetails';
import cashAndCustodyConfirmProduct from '../components/managedFund/cashAndCustody/cashAndCustodyConfirmProduct';
import cashAndCustodyKyc from '../components/managedFund/cashAndCustody/cashAndCustodyCashKyc';
import cashAndCustodyCapacityType from '../components/managedFund/cashAndCustody/cashAndCustodyCapacityType';
import cashAndCustodyBeneficialOwnerDetails from '../components/managedFund/cashAndCustody/cashAndCustodyBeneficialOwnerDetails';
import cashAndCustodyAddressDetails from '../components/managedFund/cashAndCustody/cashAndCustodyAddressDetails';
import cashAndCustodyContactDetails from '../components/managedFund/cashAndCustody/cashAndCustodyContactDetails';
import cashAndCustodyAccountPurpose from '../components/managedFund/cashAndCustody/cashAndCustodyAccountPurpose';
import cashAndCustodyInstructingParty from '../components/managedFund/cashAndCustody/cashAndCustodyInstructingParty';
import cashAndCustodyConfirmAuthorisers from '../components/managedFund/cashAndCustody/cashAndCustodyConfirmAuthorisers';
import cashAndCustodyConfirmApplication from '../components/managedFund/cashAndCustody/cashAndCustodyConfirmApplication';

//Electronic Indemnity
import ElectronicIndemnityPage from '../components/electronicIndemnity/ElectronicIndemnityPage';
import electronicIndemnityClauses from '../components/electronicIndemnity/clauses';
import authorisedIndividual from '../components/electronicIndemnity/authorisedIndividual';
import confirmAuthorisers from '../components/electronicIndemnity/confirmAuthorisers';
import completeElectronicIndemnity from '../components/electronicIndemnity/CompleteElectronicIndemnity';

// Shari’ah BCA

import shariahBcaEntity from '../components/shariahBca/shariahBcaEntityPage';
import shariahBcaDetails from '../components/shariahBca/shariahBcaDetailsPage';
import shariahBcaConfirmProduct from '../components/shariahBca/shariahBcaConfirmProductPage';
import shariahBcaKyc from '../components/shariahBca/shariahBcaKycPage';
import shariahBcaConfirmAuthorisers from '../components/shariahBca/shariahConfirmAuthorisers';
import shariahBcaCompleteApplication from '../components/shariahBca/shariahCompleteapplication';

// Savings Account
import savingsAccountEntity from '../components/savingsAccount/savingsAccountEntityPage';
import savingsAccountConfirmDetails from '../components/savingsAccount/savingsAccountConfirmDetails';
import savingsAccountConfirmProduct from '../components/savingsAccount/savingsAccountConfirmProduct';
import savingsAccountKYC from '../components/savingsAccount/savingsAccountKYC'; 
import savingsAccountConfirmAuthorisers from '../components/savingsAccount/savingsAccountConfirmAuthorisers'; 
import savingsAccountComplete  from '../components/savingsAccount/savingsAccountCompleteApplication';


const AppRouter = (props) => (
    <BrowserRouter>
        <div style={{backgroundColor: 'white', flex: 1}}>
            <Route path="/" component={NavBar}/>
            <StepIndicatorNav {...props}/>
            <ScrollToTop/>
            <div className="row" style={{ margin:'0', minHeight:'80vh'}}>
                <Route exact path="/" component={requireAuth(HomePage)}/>
                <Route exact path="/launch" component={TokenReceiver}/>
                <Route exact path="/staff" component={requireAuth(HomePage)}/>
                <Route exact path="/logout" component={Logout}/>

                <Route exact path={newAccountOpeningRoutes.newAccount} component={HierachyListPage}/>
                <Route path={newAccountOpeningRoutes.confirmdetails} component={EntityDetailsPage}/>
                <Route path={newAccountOpeningRoutes.confirmproduct} component={ConfirmProductPage}/>
                <Route path={newAccountOpeningRoutes.kyc} component={kyc}/>
                <Route path={newAccountOpeningRoutes.confirmauthorisers} component={ConfirmAuthorisersPage}/>
                <Route exact path={newAccountOpeningRoutes.confirmapplication} component={ConfirmApplicationPage}/>
                <Route exact path = {newCustodyAccountRoutes.custodyaccount} component={NewCustodyAccount}/>
                <Route path={newCustodyAccountRoutes.accountrelations} component={AccountRelationsPage}/>
                <Route path = {newCustodyAccountRoutes.accountcashpurpose} component = {AccountCashPurposePage}/>
                <Route path={newCustodyAccountRoutes.beneficialowner} component={BeneficialOwnerPage}/>
                <Route path={newCustodyAccountRoutes.addressdetails} component={AddressDetailsPage}/>
                <Route path={newCustodyAccountRoutes.contactdetails} component={ContactDetailsPage}/>
                <Route path={newCustodyAccountRoutes.instructingparty} component={InstructingPartyPage}/>
                <Route path={newCustodyAccountRoutes.confirmauthorisers} component={CustodyConfirmPage}/>
                <Route path={newCustodyAccountRoutes.complete} component={CompletionPage}/>

                <Route exact path={globalMarketAccountRoutes.entity} component={HierachyListPage}/>
                <Route exact path={globalMarketAccountRoutes.confirmentity} component={ConfirmEntityPage}/>
                {/* <Route exact path={globalMarketAccountRoutes.customers} component={CustomersPage}/> */}
                <Route exact path={globalMarketAccountRoutes.instructions} component={SettlementInstructionsPage}/>
                <Route path={globalMarketAccountRoutes.complete} component={SSICompletionPage}/>

                <Route exact path={menuRoutes.newEntity} component={OnBoardingPage}/>
                <Route exact path={menuRoutes.track} component={TrackingLandingPage}/>
                <Route path={`${menuRoutes.track}/transactions`} component={PaymentTrackingPage}/>
                <Route path="/onboard/lookup" component={EntityLookupPage}/>
                <Route path="/onboard/reginfo" component={RegulatoryInfoPage}/>
                <Route path="/onboard/details" component={NewEntityDetailsPage}/>
                <Route path="/onboard/kyc" component={NewEntitykycPage}/>
                <Route path={'/onboard/confirmed'} component={OnboardConfirmationPage}/>
                <Route path="/saveapplication" component={SaveApplicationPage}/>
                <Route path="/delegate" component={DelegateAuthorityPage}/>
                <Route path="/rules" component={ConfigureRulesPage}/>
                <Route path="/reporting" component={ReportsPage}/>
                <Route path="/new-custody-account" component={NewCustodyAccount}/>
                <Route exact path={kycRoutes.root} component={HierachyListPage}/>
                <Route path={kycRoutes.entityDetails} component={KycMaintenance}/>
                <Route path={kycRoutes.peopleDetails} component={PeoplePage}/>
                <Route path={kycRoutes.documents} component={DocumentsPage}/>
                <Route path={kycRoutes.complete} component={KycCompletePage}/>
                <Route exact path={signatoryRoots.root} component={SignApplications}/>
                <Route exact path={signatoryRoots.addStart} component={HierachyListPage}/>
                <Route path={signatoryRoots.addInstructions} component={SignatoryReview}/>
                <Route exact path={signatoryRoots.confirmAuthorizers} component={SignatoryConfirmPage}/>
                <Route exact path={signatoryRoots.deleteStart} component={HierachyListPage}/>
                <Route path={signatoryRoots.deleteInstructions} component={DeleteSignatory}/>

                <Route exact path={remediationRoutes.root} component={LegalRemediation}/>
                <Route path={remediationRoutes.legals} component={Legals}/>
                <Route path={remediationRoutes.complete} component={EbtcComplete}/>


                

                <Route exact path={businessOnlineRoutes.businessOnline} component={BusinessOnlinePage}/>
                <Route path={businessOnlineRoutes.transaction} component={TransactionDetailsPage}/>
                <Route path={businessOnlineRoutes.accountSetup} component={AccountSetupPage}/>
                <Route path={businessOnlineRoutes.authorization} component={AuthorizationDetailsPage}/>
                <Route path={businessOnlineRoutes.contactDetails} component={BolContactInfo}/>
                <Route path={businessOnlineRoutes.complete} component={CompletePage}/>

                <Route exact path={newBolProfileRoutes.bol} component={NewBolPage}/>
                <Route path={newBolProfileRoutes.details} component={CustomerDetailsPage}/>
                <Route path={newBolProfileRoutes.channel} component={ChannelPage}/>
                <Route path={newBolProfileRoutes.options} component={ProfileProcessingOptionsPage}/>
                <Route path={newBolProfileRoutes.accounts} component={BankAccountsPage}/>
                <Route path={newBolProfileRoutes.users} component={UsersInfoPage}/>
                <Route path={newBolProfileRoutes.complete} component={ApplicationCompletePage}/>

                <Route exact path={digitalCreditRoutes.root} component={DigitalCreditLandingPage}/>
                <Route exact path={digitalCreditRoutes.details} component={DigitalCreditPage}/>
                <Route exact path={digitalCreditRoutes.complete} component={CreditConfirmationPage}/>
                <Route exact path={maintenanceRoutes.root} component={MaintenancePage}/>
                <Route exact path={tpfaRoutes.root} component={TPFAStart}/>
                <Route path={tpfaRoutes.thirdParty} component={ThirdPartyDetails}/>
                <Route path={tpfaRoutes.tpfaConfirmAuthorisers} component={TPFAConfirmAuthorisers}/>
                <Route path={tpfaRoutes.complete} component={TPFAComplete}/>

                {/* managed cash account routes */}
                <Route exact path={managedFundRoutes.mFtile} component={ManagedFundTiles}/>
                <Route exact path={managedFundRoutes.cashKyc} component={cashKyc}/>
                <Route exact path={managedFundRoutes.cashAccount} component={CashAccount}/>
                <Route exact path={managedFundRoutes.cashConfirmdetails} component={cashConfirmdetails}/>
                <Route exact path={managedFundRoutes.cashConfirmProductdetails} component={cashConfirmProduct}/>
                <Route exact path={managedFundRoutes.cashConfirmAuthorisers} component={cashConfirmAuthorisersPage}/>
                <Route exact path={managedFundRoutes.cashConfirmApplication} component={cashConfirmApplicationPage}/>
                
                {/* managed cash account routes */}
                <Route exact path={managedFundRoutes.custodyAccount} component={custodyAccount}/>
                <Route exact path={managedFundRoutes.custodyAccountRelations} component={custodyAccountRelations}/>
                <Route exact path={managedFundRoutes.custodyBeneficialOwnerDetails} component={custodyBeneficialOwnerDetails}/>
                <Route exact path={managedFundRoutes.custodyAddressDetails} component={custodyAddressDetails}/>
                <Route exact path={managedFundRoutes.custodyContactDetails} component={custodyContactDetails}/>
                <Route exact path={managedFundRoutes.custodyCashAccountPurpose} component={custodyCashAccountPurpose}/>
                <Route exact path={managedFundRoutes.custodyInstructingParty} component={custodyInstructingParty}/>
                <Route exact path={managedFundRoutes.custodyIsOnlineAndDocUpload} component={custodyIsOnlineAndDocUpload}/>
                <Route exact path={managedFundRoutes.custodyConfirmAuthorizers} component={custodyConfirmAuthorizers}/>
                <Route exact path={managedFundRoutes.custodyConfirmApplicationPage} component={custodyConfirmApplicationPage}/>

                {/* managed cash and custody account routes */}
                <Route exact path={managedFundRoutes.cashAndCustodyAccount} component={cashAndCustodyAccount}/>
                <Route exact path={managedFundRoutes.cashAndCustodyConfirmdetails} component={cashAndCustodyConfirmdetails}/>
                <Route exact path={managedFundRoutes.cashAndCustodyConfirmProduct} component={cashAndCustodyConfirmProduct}/>
                <Route exact path={managedFundRoutes.cashAndCustodyKyc} component={cashAndCustodyKyc}/>
                <Route exact path={managedFundRoutes.cashAndCustodyCapacityType} component={cashAndCustodyCapacityType}/>
                <Route exact path={managedFundRoutes.cashAndCustodyBeneficialOwnerDetails} component={cashAndCustodyBeneficialOwnerDetails}/>
                <Route exact path={managedFundRoutes.cashAndCustodyAddressDetails} component={cashAndCustodyAddressDetails}/>
                <Route exact path={managedFundRoutes.cashAndCustodyContactDetails} component={cashAndCustodyContactDetails}/>
                <Route exact path={managedFundRoutes.cashAndCustodyAccountPurpose} component={cashAndCustodyAccountPurpose}/>
                <Route exact path={managedFundRoutes.cashAndCustodyInstructingParty} component={cashAndCustodyInstructingParty}/>
                
                <Route exact path={managedFundRoutes.cashAndCustodyConfirmAuthorisers} component={cashAndCustodyConfirmAuthorisers}/>
                <Route exact path={managedFundRoutes.cashAndCustodyConfirmApplication} component={cashAndCustodyConfirmApplication}/>

                {/* Electronic Indemnity */}
                <Route exact path={electronicIndemnityRoutes.electronicIndemnity} component={ElectronicIndemnityPage}/>
                <Route exact path={electronicIndemnityRoutes.electronicIndemnityClauses} component={electronicIndemnityClauses}/>
                <Route exact path={electronicIndemnityRoutes.authorisedIndividual} component={authorisedIndividual}/>
                <Route exact path={electronicIndemnityRoutes.confirmAuthorisers} component={confirmAuthorisers}/>
                <Route exact path={electronicIndemnityRoutes.completeElectronicIndemnity} component={completeElectronicIndemnity}/>

                {/* Shari’ah BCA */}

                <Route exact path={shariahBcaRoutes.shariahBcaEntity} component={shariahBcaEntity}/>
                <Route exact path={shariahBcaRoutes.shariahBcaConfirmDetails} component={shariahBcaDetails}/>
                <Route exact path={shariahBcaRoutes.shariahBcaConfirmProduct} component={shariahBcaConfirmProduct}/>
                <Route exact path={shariahBcaRoutes.shariahBcaKYC} component={shariahBcaKyc}/>
                <Route exact path={shariahBcaRoutes.shariahBcaConfirmAuthorisers} component={shariahBcaConfirmAuthorisers}/>
                <Route exact path={shariahBcaRoutes.shariahBcaComplete} component={shariahBcaCompleteApplication}/>

                {/* Savings Account */}
                
                <Route exact path={savingsAccountRoutes.savingsAccountEntity} component={savingsAccountEntity}/>
                <Route exact path={savingsAccountRoutes.savingsAccountConfirmDetails} component={savingsAccountConfirmDetails}/>
                <Route exact path={savingsAccountRoutes.savingsAccountConfirmProduct} component={savingsAccountConfirmProduct}/>
                <Route exact path={savingsAccountRoutes.savingsAccountKYC} component={savingsAccountKYC}/>
                <Route exact path={savingsAccountRoutes.savingsAccountConfirmAuthorisers} component={savingsAccountConfirmAuthorisers}/>
                <Route exact path={savingsAccountRoutes.savingsAccountComplete} component={savingsAccountComplete}/>
                
                
            </div>
            <Footer/>
            <PageLoading/>
            <ErrorMessage {...props}/>
        </div>
    </BrowserRouter>
);

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};

export default AppRouter;
