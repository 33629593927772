import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function accountsReducer(state = initialState.accounts, action) {
    const objName = action && action.objName ? action.objName : 'accounts';

    switch (action.type) {
        case types.FETCH_ACCOUNTS_SUCCESS:
            return {...state, [objName]: action.accounts};

        case types.FETCH_ACCOUNTS_EMPTY:
            return {...state, accountsEmpty: action.accountsEmpty, [objName]: action.accounts};

        case types.UPDATE_ACCOUNTS:
            return {...state,[objName]: action.accounts};
        case types.FETCH_ACCOUNT_TRANSACTIONS_SUCCESS:
            return {...state, transactions: action.transactions};

        case types.FETCH_TRANSACTION_SUCCESS:
            return {...state, selectedTransactionDetails: action.selectedTransactionDetails};

        default:
            return state;
    }
}